import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loader/GlobalLoader';
import LayoutWrapper from 'containers/common/layout';
import ScrollToTop from './ScrollToTop';

const PublicRoute = lazy(() => import('./PublicRoute'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const PartnerRoutes = lazy(() => import('./PartnerRoutes'));
const UserRoutes = lazy(() => import('./UserRoutes'));
const HomePage = lazy(() => import('containers/pages/home'));

// Auth Routes
const Login = lazy(() => import('containers/pages/auth/login'));
const SignUp = lazy(() => import('containers/pages/auth/signup'));
const ForgotPassword = lazy(() => import('containers/pages/auth/forgotPassword'));
const OTPVerification = lazy(() => import('containers/pages/auth/forgotPassword/OtpVerification'));
const SignUpOtp = lazy(() => import('containers/pages/auth/signup/components/SignUpOtp'));
const PartnerGoogleSignUp = lazy(() => import('containers/pages/auth/partnerSignup/PartnerGoogleSignup'));

// User Routes
const WriteArticle = lazy(() => import('containers/pages/articles/publisArticle'));
const UserProfile = lazy(() => import('containers/pages/user/profile'));
const UserArticles = lazy(() => import('containers/pages/user/articles'));
const UserProfileSetting = lazy(() => import('containers/pages/profile'));

// Partner Routes
const Partner = lazy(() => import('containers/pages/partners/brands'));
const AddBrand = lazy(() => import('containers/pages/partners/brands/AddBrand'));
const EditBrand = lazy(() => import('containers/pages/partners/brands/EditBrand'));
const PartnerBrandsList = lazy(() => import('containers/pages/user/profile/PartnerBrandsList'));

// Subscription Plans & Purchase points & Withdraw cash
const Plans = lazy(() => import('containers/pages/subscription'));
const PurchasePoints = lazy(() => import('containers/pages/purchasePoints'));
const PointsPayment = lazy(() => import('containers/pages/purchasePoints/components/PointsPayment'));
const WithdrawCash = lazy(() => import('containers/pages/withdrawCash'));

// Article Routes
const ArticleDetails = lazy(() => import('containers/pages/common/articleDetails'));
const Articles = lazy(() => import('containers/pages/articles/index'));

// Events And Blogs And Coupons
const EventsAndBlogs = lazy(() => import('containers/pages/blogs'));
const BlogDetails = lazy(() => import('containers/pages/blogs/blogDetails'));
const Coupons = lazy(() => import('containers/pages/coupons'));

// Discovery
const Discovery = lazy(() => import('containers/pages/discovery'));

// Contact Us and About Us and Privacy Policy
const Terms = lazy(() => import('containers/pages/terms'));
const ContactUs = lazy(() => import('containers/pages/contactUs'));
const PrivacyPolicy = lazy(() => import('containers/pages/privacyPolicy'));

function AppRoutes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader />}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LayoutWrapper />}>
            <Route index element={<HomePage />} />
            <Route path="membership-plans" element={<Plans />} />
            <Route path="coupons" element={<Coupons />} />
            <Route path="discovery" element={<Discovery />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="partner/:partnerId/brands-list" element={<PartnerBrandsList />} />

            {/* Article Public Routes */}
            <Route path="articles/:query" element={<Articles />} />
            <Route path="articles/search/:query" element={<Articles />} />
            <Route path="/:category/:slug" element={<ArticleDetails />} />

            {/* Blog Public Routes */}
            <Route path="blogs">
              <Route index element={<EventsAndBlogs />} />
              <Route path=":slug/:id" element={<BlogDetails />} />
            </Route>

            {/* Auth Routes */}
            <Route path="auth" element={<PublicRoute />}>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="signup/partner" element={<PartnerGoogleSignUp />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="otp-verification" element={<OTPVerification />} />
              <Route path="signup-otp" element={<SignUpOtp />} />
            </Route>
            {/* Private Routes */}
            <Route path="/" element={<PrivateRoute />}>
              {/* Common Routes */}
              <Route path="me/settings/profile" element={<UserProfileSetting />} />
              <Route path="user/:userId" element={<UserProfile />} />
              <Route path="user/:userId/articles" element={<UserArticles />} />
              <Route path="shop/purchase-points">
                <Route index element={<PurchasePoints />} />
                <Route path="payment" element={<PointsPayment />} />
              </Route>
              {/* User Routes */}
              <Route path="user" element={<UserRoutes />}>
                <Route path="write-article" element={<WriteArticle />} />
                <Route path="withdraw" element={<WithdrawCash />} />
              </Route>
              {/* Partner Routes */}
              <Route path="partner" element={<PartnerRoutes />}>
                <Route index element={<Partner />} />
                <Route path="add-brand" element={<AddBrand />} />
                <Route path="edit-brand" element={<EditBrand />} />
              </Route>
            </Route>
            {/* Not Found */}
            <Route path="*" element={<h1>Not Found!</h1>} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoutes;
