import { useDispatch, useSelector } from 'react-redux';
import { onLoggedOut } from 'store/slices/authSlice';
import { useNavigate } from 'react-router';
import { publicApi } from 'services/public';
import { privateAPi } from 'services/private';

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector(reduxState => reduxState.auth);

  const userRole = user?.user_type;
  const UserProfile = user?.profile;
  const isPartner = user?.user_type === 'partner';
  const isUser = user?.user_type === 'user';

  const handleLogout = () => {
    navigate('/user/login');
    dispatch(onLoggedOut());
    dispatch(publicApi.util.resetApiState());
    dispatch(privateAPi.util.resetApiState());
  };

  return { handleLogout, isPartner, isUser, userRole, user, UserProfile, isAuthenticated };
};

export default useAuth;
