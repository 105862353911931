export const navbarLogoStyles = () => ({
  mr: 2,
  display: { xs: 'none', md: 'flex' },
  fontFamily: 'monospace',
  fontWeight: 700,
  letterSpacing: '.3rem',
  color: 'inherit',
  textDecoration: 'none',
});

export const navBarStyle = () => ({
  backgroundColor: '#fff',
  boxShadow: '0 0 2px gray',
  color: '#000000',

  '@media screen and (max-width: 768px)': {
    backgroundColor: '#171E60',
    color: '#fff',
  }
});

export const categoriesNavbarStyle = {
  display: 'block',
  backgroundColor: '#171E60',
  boxShadow: '0 0 2px gray',
  color: '#fff',
  padding: '10px',
  overflowX: 'auto',
  width: '100%',

  '@media screen and (min-width: 769px)': {
    display: 'none',
  },

  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const categoryBoxTitleStyles = {
  // display: '-webkit-box',
  // WebkitBoxOrient: 'vertical',
  // WebkitLineClamp: 1,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
};
