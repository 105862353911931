import React from 'react';
import { Box, Typography, Dialog } from '@mui/material';
import { Info } from '@mui/icons-material';
import propTypes from 'prop-types';

import {
  confirmationDialogStyles,
  confirmationDialogIcon,
} from 'styles/mui/containers/confirmation-dialog-style';

function WriteArticleInfoModal({ openModal, closeModal }) {
  return (
    <Dialog open={openModal} onClose={closeModal} sx={confirmationDialogStyles}>
      <Box spacing={4}>
        <Info color="success" sx={confirmationDialogIcon} onClick={closeModal} />
        <Box>
          <Typography variant="pageTitle">
            Unlock the power of article writing by reaching Challenger Level! Before you embark on this
            exciting journey, make sure to meet our criteria. Your path to Challenger awaits.
          </Typography>
          <Typography variant="pageTitle" display="block" className="mt-3">
            Thank you for your commitment!
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}

WriteArticleInfoModal.propTypes = {
  openModal: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
};

export default WriteArticleInfoModal;
