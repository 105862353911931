import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDeleteArticleDraftMutation,
  useSaveArticleDraftMutation,
  useUpdateArticleMutation,
} from 'services/private/articles';
import {
  clearArticleData,
  setLoading,
  setOpenDialog,
  updateArticleInfo,
} from 'store/slices/publishArticleSlice';

const useArticleActionButtons = () => {
  const dispatch = useDispatch();

  const userProfileId = useSelector(reduxState => reduxState.auth?.user?.profile?.id);
  const {
    articleData,
    id: articleId,
    slug,
    selectedArticle,
  } = useSelector(reduxState => reduxState.publishArticle);

  const [saveArticle, { isLoading: isArticleSaving }] = useSaveArticleDraftMutation();
  const [updateArticle, { isLoading: isArticleUpdating }] = useUpdateArticleMutation();
  const [deleteArticle] = useDeleteArticleDraftMutation();

  useEffect(() => {
    dispatch(setLoading(isArticleSaving || isArticleUpdating));
  }, [isArticleSaving, isArticleUpdating]);

  const handleSaveNewDraft = useCallback(async () => {
    if (articleData.title && articleData.article) {
      const payload = {
        title: articleData.title,
        content: articleData.article,
        author: userProfileId,
        status: 'draft',
      };

      const resp = await saveArticle(payload);

      if (resp.data) {
        dispatch(updateArticleInfo({ isSaved: true, id: resp?.data?.id }));
      }
    }
  }, [articleData, userProfileId]);

  const handleSaveDraft = useCallback(async () => {
    if (!articleId) {
      await handleSaveNewDraft();
      return;
    }

    if (articleId) {
      const payload = {
        title: articleData.title,
        content: articleData.article,
        id: articleId,
        profileId: userProfileId,
        slug,
      };

      await updateArticle(payload);
      dispatch(updateArticleInfo({ isUpdated: false }));
    }
  }, [articleData, userProfileId, articleId]);

  const handleClearDraft = useCallback(async () => {
    await deleteArticle({ slug, userProfileId });
    dispatch(clearArticleData());
  }, [articleId]);

  const handlePublishDialogOpen = useCallback(async () => {
    dispatch(setOpenDialog(true));
  }, [articleId, articleData, selectedArticle]);

  return {
    handleSaveDraft,
    handleSaveNewDraft,
    isArticleSaving,
    isArticleUpdating,
    saveArticle,
    updateArticle,
    handleClearDraft,
    handlePublishDialogOpen,
  };
};

export default useArticleActionButtons;
