export const confirmationDialogStyles = {
  borderRadius: '40px',
  height: 'auto',
  textAlign: 'center',
  '.MuiPaper-root': {
    padding: 3,
  },
};

export const confirmationDialogIcon = {
  height: '80px',
  width: '80px',
  cursor: 'pointer'
};
