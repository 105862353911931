const FooterLinks = [
  {
    id: 1,
    name: 'Terms',
    url: '/terms',
  },
  {
    id: 2,
    name: 'Privacy Policy',
    url: '/privacy-policy',
  },
  {
    id: 3,
    name: 'Contact Us',
    url: '/contact-us',
  },
  {
    id: 4,
    name: 'Business',
    url: 'https://worldtrendreview.biz/advertising',
  },
];

const NavbarLinks = [
  {
    id: 1,
    name: 'Our Story',
    url: '/our-story',
  },
  {
    id: 2,
    name: 'Membership',
    url: '/membership-plans',
  },
];

const LanguagesOptions = ['English', 'Arabic', 'Japanese', 'Korean', 'Portuguese'];

export const navOtherLinks = [
  {
    id: 1,
    name: 'Secret Box',
    url: '/coupons',
  },
  {
    id: 2,
    name: 'Discovery',
    url: '/discovery',
  },
  {
    id: 3,
    name: 'Blogs',
    url: '/blogs',
  },
];
export const categoriesData = [
  {
    id: 2,
    sub_categories: [],
    created_at: '2023-11-01T06:52:50.021910Z',
    updated_at: '2023-11-01T06:52:50.021927Z',
    name: 'First',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/loan_1_FlWCFQY.png'
  },
  {
    id: 3,
    sub_categories: [],
    created_at: '2023-11-01T06:53:03.202517Z',
    updated_at: '2024-01-11T12:11:39.334974Z',
    name: 'Second',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/make-up_HavVFMn.png'
  },
  {
    id: 4,
    sub_categories: [],
    created_at: '2023-11-01T06:53:37.902589Z',
    updated_at: '2024-01-16T07:58:10.196475Z',
    name: 'Third',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/image_dRO9si6.jpg'
  },
  {
    id: 11,
    sub_categories: [],
    created_at: '2024-01-17T12:59:23.936254Z',
    updated_at: '2024-01-26T12:16:55.336938Z',
    name: 'Fourth',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/health_VQJXYDf.png'
  },
  {
    id: 1,
    sub_categories: [],
    created_at: '2023-11-01T06:52:34.573528Z',
    updated_at: '2024-02-02T10:36:23.719183Z',
    name: 'Fifth',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/travel-luggage_9UvsVOc.png'
  },
  {
    id: 2,
    sub_categories: [],
    created_at: '2023-11-01T06:52:50.021910Z',
    updated_at: '2023-11-01T06:52:50.021927Z',
    name: 'Investment',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/loan_1_FlWCFQY.png'
  },
  {
    id: 3,
    sub_categories: [],
    created_at: '2023-11-01T06:53:03.202517Z',
    updated_at: '2024-01-11T12:11:39.334974Z',
    name: 'Beauty & Fashion',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/make-up_HavVFMn.png'
  },
  {
    id: 4,
    sub_categories: [],
    created_at: '2023-11-01T06:53:37.902589Z',
    updated_at: '2024-01-16T07:58:10.196475Z',
    name: 'Crypto',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/image_dRO9si6.jpg'
  },
  {
    id: 11,
    sub_categories: [],
    created_at: '2024-01-17T12:59:23.936254Z',
    updated_at: '2024-01-26T12:16:55.336938Z',
    name: 'Life Style',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/health_VQJXYDf.png'
  },
  {
    id: 1,
    sub_categories: [],
    created_at: '2023-11-01T06:52:34.573528Z',
    updated_at: '2024-02-02T10:36:23.719183Z',
    name: 'Trips',
    icon: 'https://trends-review.s3.amazonaws.com/media/article/Article-category-icon/travel-luggage_9UvsVOc.png'
  }
];

export { FooterLinks, NavbarLinks, LanguagesOptions };
