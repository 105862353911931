/* eslint-disable indent */
import React, { useState, useMemo, useCallback } from 'react';
import { AppBar, Stack, Button, Box, Typography, useMediaQuery, IconButton, Avatar } from '@mui/material';
import { NoteAltOutlined, Menu, KeyboardArrowDown } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from 'assets/logo.png';
import { navBarStyle } from 'styles/mui/common/navbar-styles';
import { sideBarSearchStyles } from 'styles/mui/common/layout-styles';
import { AUDIENCE, USER } from 'utilities/constants';
import SearchInput from 'shared/components/SearchInput';
import WriteArticleInfoModal from './WriteArticleInfoModal';
import GoogleTranslator from './GoogleTranslator';
import ProfileMenu from './ProfileMenu';
import OtherLinksMenu from './OtherLinksMenu';
import ArticleActionButtons from './ArticleActionButtons';

function NavBar() {
  const { pathname } = useLocation();

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [profileMenu, setProfileMenu] = useState(null);
  const [otherLinksMenu, setOtherLinksMenu] = useState(null);
  const { isAuthenticated, user } = useSelector(state => state.auth);

  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery('(max-width: 768px)');

  const handleOpenProfileMenu = useCallback(e => {
    setProfileMenu(e.currentTarget);
  }, []);

  const handleCloseProfileMenu = useCallback(() => {
    setProfileMenu(null);
  }, []);

  const handleOpenOtherLinksMenu = useCallback(e => {
    setOtherLinksMenu(e.currentTarget);
  }, []);

  const handleCloseOtherLinksMenu = useCallback(() => {
    setOtherLinksMenu(null);
  }, []);

  const handleInfoModal = useCallback(() => {
    setOpenInfoModal(state => !state);
  }, [openInfoModal]);

  const isArticleOpen = useMemo(() => pathname.includes('write-article'), [pathname]);
  const canUserWriteArticle = useMemo(
    () => !isArticleOpen && user?.user_type === USER && user?.profile?.author_rank !== AUDIENCE,
    [user, isArticleOpen, isSmallDevice]
  );

  return (
    <AppBar position="sticky" sx={navBarStyle()}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isSmallDevice ? 'space-between' : 'end'}
        className="py-2 px-3"
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {isSmallDevice && (
            <>
              <Box component={Link} to="/" sx={{ maxWidth: '50px' }}>
                <img className="img-fluid" src={logo} alt="Logo" />
              </Box>

              {!isArticleOpen && (
                <Box sx={sideBarSearchStyles} component="form" noValidate autoComplete="off">
                  <SearchInput />
                </Box>
              )}
            </>
          )}
        </Stack>

        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          {isSmallDevice && !isArticleOpen && (
            <IconButton variant="contained" color="white" size="small" onClick={handleOpenOtherLinksMenu}>
              <Menu />
            </IconButton>
          )}

          <GoogleTranslator />

          {isArticleOpen && <ArticleActionButtons isSmallDevice={isSmallDevice} />}

          {!isAuthenticated && (
            <>
              <Link to="/auth/login">
                <Button variant="contained" color="primary" fontSize="small">
                  {t('Login')}
                </Button>
              </Link>

              <Link to="/auth/signup">
                <Button variant="contained" color="secondary" fontSize="small">
                  {t('SignUp')}
                </Button>
              </Link>
            </>
          )}

          {canUserWriteArticle && isAuthenticated && !isSmallDevice && (
            <Link to="/user/write-article">
              <Stack
                sx={{ height: '40px' }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="body2" sx={{ fontSize: '18px' }} color="primary">
                  {t('Write')}
                </Typography>
                <NoteAltOutlined sx={{ fontSize: '30px' }} color="primary" />
              </Stack>
            </Link>
          )}

          {!isArticleOpen &&
            isAuthenticated &&
            !isSmallDevice &&
            user?.user_type === 'user' &&
            user?.profile?.author_rank === 'audience' && (
              <Stack
                sx={{ height: '40px' }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                className="pointer"
                onClick={handleInfoModal}
              >
                <Typography variant="body2" sx={{ fontSize: '18px' }} color="primary">
                  {t('Write')}
                </Typography>
                <NoteAltOutlined sx={{ fontSize: '30px' }} color="primary" />
              </Stack>
            )}

          {isSmallDevice && isAuthenticated && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              className="pointer"
              onClick={handleOpenProfileMenu}
            >
              <Avatar sx={{ width: 35, height: 35 }} alt="User" src={user?.profile?.profile_picture} />

              <Typography
                className="d-none d-sm-block"
                variant="body2"
                color="white"
                textTransform="capitalize"
              >
                {user?.profile?.first_name || 'NA'}
              </Typography>

              <KeyboardArrowDown className="d-none" />
            </Stack>
          )}
        </Stack>
      </Stack>

      <WriteArticleInfoModal openModal={openInfoModal} closeModal={handleInfoModal} />

      <ProfileMenu
        anchorEl={profileMenu}
        handleClose={handleCloseProfileMenu}
        canUserWriteArticle={canUserWriteArticle}
      />

      <OtherLinksMenu anchorEl={otherLinksMenu} handleClose={handleCloseOtherLinksMenu} />
    </AppBar>
  );
}

export default NavBar;
