import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Box, ListItemButton, Typography, useMediaQuery, List, ListItem, Tooltip } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import { Link, useMatch } from 'react-router-dom';
import { v4 } from 'uuid';

import { convertSpacesToPlus } from 'utilities/helpers';
import { getListItemBtnStyles, subMenuStyle, subMenuItemStyle } from 'styles/mui/common/layout-styles';
import 'styles/containers/common/dropdown.scss';

function SidebarLink({ path, title, icon, isMenu, handleClick, subCategories, id, collapse }) {
  const [isSubCatOpen, setIsSubCatOpen] = useState(false);
  const isActivePath = useMatch(path || '');
  const isSmallDevice = useMediaQuery('@media screen and (max-width: 768px)');

  const handleIsSubCatOpen = () => {
    setIsSubCatOpen(state => !state);
  };

  return (
    <Box className="translate">
      <Tooltip title={title} placement="right" disableHoverListener={!isSmallDevice}>
        <ListItemButton
          onClick={handleClick}
          sx={getListItemBtnStyles(isMenu || !path ? false : isActivePath)}
        >
          <Box sx={{ flex: 1 }}>
            <Link to={path} state={{ id, filter_type: 'category' }}>
              <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                {icon && isMenu && (
                  <img src={icon} width="20px" height="20px" loading="lazy" alt="category" />
                )}
                {!isMenu && icon ? icon : ''}

                <Typography variant="body2" className="sidebar-hide-item wrapText">
                  {title}
                </Typography>
              </Box>
            </Link>
          </Box>
          {isMenu &&
            !isSmallDevice &&
            (isSubCatOpen ? (
              <Box className="sidebar-hide-item">
                <KeyboardArrowDown color="white" onClick={() => handleIsSubCatOpen()} />
              </Box>
            ) : (
              <Box className="sidebar-hide-item">
                <KeyboardArrowRight color="white" onClick={() => handleIsSubCatOpen()} />
              </Box>
            ))}
        </ListItemButton>
      </Tooltip>

      {isSubCatOpen && (
        <List sx={subMenuStyle(collapse)}>
          {subCategories?.map(category => (
            <Link
              key={category?.id}
              to={`/articles/${convertSpacesToPlus(category?.name)}`}
              state={{ id: category?.id, filter_type: 'subcategory' }}
            >
              <ListItem sx={subMenuItemStyle}>{category.name}</ListItem>
            </Link>
          ))}
        </List>
      )}
    </Box>
  );
}

SidebarLink.propTypes = {
  title: propTypes.string.isRequired,
  path: propTypes.string,
  icon: propTypes.any,
  isMenu: propTypes.bool,
  handleClick: propTypes.func,
  subCategories: propTypes.array,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
  collapse: propTypes.bool,
};

SidebarLink.defaultProps = {
  path: '',
  icon: null,
  isMenu: false,
  handleClick: () => {},
  subCategories: [],
  id: v4(),
  collapse: false,
};

export default SidebarLink;
