/* eslint-disable no-plusplus */
import { privateAPi } from '.';

export const articlesApi = privateAPi.injectEndpoints({
  endpoints: build => ({
    getRecomendedArticles: build.query({
      query: () => ({
        url: '/api/article/recommended-articles/',
        method: 'GET',
      }),
    }),
    getRelatedArticles: build.query({
      query: id => ({
        url: `/api/article/related-articles/?category=${id}`,
        method: 'GET',
      }),
    }),

    getAllArticlesLikes: build.query({
      query: () => ({
        url: '/api/article/likes_count/',
        method: 'GET',
      }),
      providesTags: ['GetAllArticlesLikes'],
    }),

    getCategoryArticles: build.query({
      query: id => ({
        url: `/api/article/category-article/${id}/`,
        method: 'GET',
      }),
      providesTags: ['GetCategoryArticles'],
    }),

    getArticleDetail: build.query({
      query: body => ({
        url: `/api/article/articles/${body?.slug}/`,
        params: {
          author: body?.profileId || undefined,
        },
        method: 'GET',
      }),
      providesTags: ['GetArticleDetail'],
    }),

    getUserArticles: build.query({
      query: body => {
        const { userId, limit, offset } = body;
        return {
          url: `/api/article/articles/?author=${userId}&limit=${limit}&offset=${offset}`,
          method: 'GET',
        };
      },
      providesTags: ['GetUserArticles'],
    }),

    uploadArticleImages: build.mutation({
      query: body => {
        const formData = new FormData();
        formData.append('image', body?.image);
        return {
          url: '/api/article/article-content-images/',
          method: 'POST',
          body: formData,
        };
      },
    }),

    publishArticle: build.mutation({
      query: body => {
        const formData = new FormData();
        body?.tags.forEach(tag => formData.append('tags', tag));
        formData.append('title', body?.title);
        formData.append('subcategory', body?.subcategory);
        formData.append('content', body?.content);
        formData.append('article_type', body?.article_type);
        formData.append('status', 'submit');
        formData.append('language', body?.language);

        if (typeof body.thumbnail !== 'string') {
          formData.append('thumbnail', body?.thumbnail);
        }

        return {
          url: body?.slug
            ? `/api/article/articles/${body?.slug}/?author=${body?.profileId}`
            : '/api/article/articles/',
          method: body?.id ? 'PUT' : 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['GetAllArticles', 'GetArticleDetail', 'GetUserArticles', 'GetDraftArticle'],
    }),

    saveArticleDraft: build.mutation({
      query: body => ({
        url: '/api/article/articles/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetAllArticles', 'GetArticleDetail', 'GetUserArticles'],
    }),

    updateArticle: build.mutation({
      query: body => ({
        url: `/api/article/articles/${body?.slug}/?author=${body?.profileId}`,
        method: 'PUT',
        body: {
          title: body?.title,
          content: body?.content,
        },
      }),
      invalidatesTags: ['GetAllArticles', 'GetUserArticles'],
    }),

    getDraftArticle: build.query({
      query: body => ({
        url: `/api/article/articles/${body.slug}/?author=${body.authorid}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['GetDraftArticle'],
    }),

    deleteArticleDraft: build.mutation({
      query: body => ({
        url: `/api/article/articles/${body.slug}/?author=${body.userProfileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetAllArticles', 'GetArticleDetail', 'GetUserArticles'],
    }),

    deletArticle: build.mutation({
      query: slug => ({
        url: `/api/profile/partner-profile/${slug}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetAllArticles', 'GetArticleDetail', 'GetUserArticles'],
    }),
  }),
});

export const {
  useGetAllArticlesLikesQuery,
  useGetArticleDetailQuery,
  useGetUserArticlesQuery,
  usePublishArticleMutation,
  useDeletArticleMutation,
  useGetRecomendedArticlesQuery,
  useGetRelatedArticlesQuery,
  useSaveArticleDraftMutation,
  useUpdateArticleMutation,
  useGetDraftArticleQuery,
  useDeleteArticleDraftMutation,
} = articlesApi;
