import { publicApi } from '.';

export const articlesApi = publicApi.injectEndpoints({
  endpoints: build => ({
    getFooterArticles: build.query({
      query: () => ({
        url: 'api/article/footer-article/',
        method: 'GET',
      }),
    }),
    getHotBrands: build.query({
      query: () => ({
        url: '/api/admin-panel/partner-hot-brand/',
        method: 'GET',
      }),
    }),
    getLandingPageArticles: build.query({
      query: body => {
        const { page } = body;
        const offset = page * 10;
        return {
          url: `/api/article/landing-page/?limit=${10}&offset=${offset}`,
          method: 'GET',
        };
      },
      providesTags: ['GetLandingPageArticles'],
    }),

    getLandingPageLanguageArticles: build.query({
      query: body => {
        const { language, page } = body;
        const lan = language.toLowerCase();
        const offset = page * 10;
        return {
          url: `api/article/article-filter-by-language/?language=${lan}&limit=${10}&offset=${offset}`,
          method: 'GET',
        };
      },
      providesTags: ['GetLandingPageLanguageArticles'],
    }),

    getLatestNewArticle: build.query({
      query: () => ({
        url: '/api/article/latest-articles/',
        method: 'GET',
      }),
    }),

    getSecondLatestArticle: build.query({
      query: () => ({
        url: '/api/article/second-latest-articles/',
        method: 'GET',
      }),
    }),

    getCategoryArticles: build.query({
      query: body => {
        const { state, limit, offset, articleTtype } = body;
        return {
          url: `/api/article/article-filter/?filter_type=${state?.filter_type}&id=${state?.id}&limit=${limit}&offset=${offset}&article_type=${articleTtype}`,
          method: 'GET',
        };
      },
      providesTags: ['GetCategoryArticles'],
    }),

    getSearchedArticles: build.query({
      query: body => {
        const { query, limit, offset, articleTtype } = body;
        return {
          url: `/api/article/article-search/?search=${query}&limit=${limit}&offset=${offset}&article_type=${articleTtype}`,
          method: 'GET',
        };
      },
    }),

    getArticleDetail: build.query({
      query: id => ({
        url: `/api/profile/partner-profile/${id}/`,
        method: 'GET',
      }),
      providesTags: ['GetArticleDetail'],
    }),

    getUserArticles: build.query({
      query: body => ({
        url: `/api/article/articles/?author=${body?.userId}`,
        method: 'GET',
      }),
      providesTags: ['GetUserArticles'],
    }),

    getLiveRankArticles: build.query({
      query: () => ({
        url: '/api/article/article-rank-list/',
        method: 'GET',
      }),
    }),

    getLiveRankAuthors: build.query({
      query: () => ({
        url: '/api/profile/user-ranking/',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetLatestNewArticleQuery,
  useGetLandingPageArticlesQuery,
  useGetLiveRankArticlesQuery,
  useGetLiveRankAuthorsQuery,
  useGetSearchedArticlesQuery,
  useGetCategoryArticlesQuery,
  useGetArticleDetailQuery,
  useGetUserArticlesQuery,
  useGetHotBrandsQuery,
  useGetSecondLatestArticleQuery,
  useGetLandingPageLanguageArticlesQuery,
  useGetFooterArticlesQuery,
} = articlesApi;
