import React from 'react';
import { Box } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';

function GlobalLoader() {
  return (
    <Box sx={{ height: '100vh' }} className="d-flex align-items-center justify-content-center">
      <ThreeDots
        height="70"
        width="70"
        radius="9"
        color="#00000"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible
      />
    </Box>
  );
}

export default GlobalLoader;
