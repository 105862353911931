import React from 'react';
import {
  Stack,
  Grid,
  Box,
  Typography,
  ImageListItem,
  ImageList,
  Container,
  Skeleton,
  Divider,
} from '@mui/material';
import { Instagram, Twitter, LinkedIn, Telegram } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getCurrentYear, replaceSpacesWithDashes } from 'utilities/helpers';
import { useGetFooterArticlesQuery } from 'services/public/articles';
import { getFooterIconStyles, footerStyle, footerCopyrightStyle } from 'styles/mui/common/footer-styles';
import Logo from 'assets/Main-Logo-Blue.png';
import { FooterLinks } from '../utilities/data';

function Footer() {
  const { t } = useTranslation();
  const { data } = useGetFooterArticlesQuery();

  return (
    <>
      <Divider className="mt-5" />
      <Box sx={footerStyle}>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={2} className="d-flex flex-column align-items-center text-center">
              <Box className="mb-2">
                <img src={Logo} alt="wtr" height="auto" width="110" />
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="my-4">
              <Stack className="d-flex flex-column gap-2">
                <Typography variant="h6" align="center">
                  {t('Links')}
                </Typography>
                <Stack direction="row" justifyContent="center" spacing={3} className="translate">
                  {FooterLinks.map(link => (
                    link.name === 'Business' ?
                      <a key={link.id} href={link.url} target="_blank" rel="noreferrer">{link.name}</a> : (
                        <Link to={link.url} className="link" key={link.id}>
                          <Typography variant="p">{link.name}</Typography>
                        </Link>
                      )

                  ))}
                </Stack>
                <Box className="customFlexCenter gap-4">
                  {/* <a
                    href="https://www.facebook.com/profile.php?id=61552195194132&mibextid=LQQJ4d"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook fontSize="large" sx={getFooterIconStyles('#3b5998')} />
                  </a> */}
                  <a
                    href="https://www.instagram.com/worldtrendreview?igsh=ZW1tdDlqczRqc2x4"
                    target="_blank"
                    rel="noreferrer"
                  ><Instagram fontSize="large" sx={getFooterIconStyles('#962fbf')} />
                  </a>

                  <a
                    href="https://twitter.com/we_wtr"
                    target="_blank"
                    rel="noreferrer"
                  > <Twitter fontSize="large" sx={getFooterIconStyles('#00acee')} />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/world-trend-review/"
                    target="_blank"
                    rel="noreferrer"
                  > <LinkedIn fontSize="large" sx={getFooterIconStyles('#00acee')} />
                  </a>

                  <a
                    href="https://t.me/worldtrendreview"
                    target="_blank"
                    rel="noreferrer"
                  > <Telegram fontSize="large" sx={getFooterIconStyles('#3b5998')} />
                  </a>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} className="d-flex justify-content-center">
              <Box>
                <Typography variant="h6">{t('Articles')}</Typography>
                {data ? (
                  <ImageList sx={{ width: '100%', maxWidth: '225px', mt: 1 }} cols={3} rowHeight={60}>
                    {data?.map(article => (
                      <Link
                        to={`/${replaceSpacesWithDashes(article?.category_name)}/${article?.slug}`}
                        state={article}
                        key={article?.id}
                      >
                        <ImageListItem key={article?.id}>
                          <img style={{ height: '30px' }} srcSet={article.thumbnail} src={article.thumbnail} alt={article.title} />
                        </ImageListItem>
                      </Link>
                    ))}
                  </ImageList>
                ) : (
                  <>
                    <Skeleton variant="rectangular" width={200} height={60} />
                    <Skeleton variant="rectangular" width={200} height={60} />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={footerCopyrightStyle}>
        <Typography variant="body2">Copyright &copy; {getCurrentYear()} World Trend Review</Typography>
      </Box>
    </>
  );
}

export default Footer;
