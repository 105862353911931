import { styled, TextField as MuiTextField } from '@mui/material';

const SearchTextField = styled(MuiTextField)({
  width: '100%',
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    '& input': {
      color: '#fff',
      background: 'rgb(1,9,87)',
      fontSize: '14px',
      height: '28px',
      transition: 'all 0.3s',
      padding: '5px 10px',
      border: 'none',
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px rgb(1,9,87) inset',
        backgroundColor: 'rgb(1,9,87)',
        border: 'none',
        color: '#fff !important',
      },
    },
    borderRadius: '0',
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },

  '& .MuiInputAdornment-root': {
    '& button': {
      border: 'none',
    },

    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
  '&:-webkit-autofill': {
    WebkitBoxShadow: 'transparent',
    WebkitTextFillColor: '#fff',
  },
});

export default SearchTextField;
