import { publicApi } from '.';

export const categoryApi = publicApi.injectEndpoints({
  endpoints: build => ({
    getCategories: build.query({
      query: () => ({
        url: '/api/article/category/',
        method: 'GET',
      }),
      providesTags: ['GetCategories'],
    }),
    getSubcategories: build.query({
      query: id => ({
        url: `/api/article/sub-category/${id}/`,
        method: 'GET',
      }),
      providesTags: ['GetCategories', 'GetSubcategories'],
    }),
    getAllSubcategories: build.query({
      query: () => ({
        url: '/api/article/sub-category-list/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCategoriesQuery, useGetSubcategoriesQuery, useGetAllSubcategoriesQuery } = categoryApi;
