import React, { useMemo, useState } from 'react';
import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { useGetAllBannersQuery } from 'services/public/banners';
import { announcementBarStyle } from 'styles/mui/common/layout-styles';

function NotificationBar() {
  const [showBar, setShowBar] = useState(true);
  const { data } = useGetAllBannersQuery();

  const notificationBar = useMemo(() => data?.find(obj => obj.banner_type === 'notification_bar'), [data]);

  if (!showBar || !notificationBar?.is_published) return null;

  return (
    <Box sx={announcementBarStyle}>
      <Box className={`${showBar ? 'd-flex' : 'd-none'} justify-content-start align-items-center noTextWrap py-1`}>
        <a
          href={notificationBar?.banner_url}
          target="_blank"
          rel="noreferrer"
          className="flex-grow-1 d-flex justify-content-center"
        >
          <Typography variant="caption" display="block">
            {notificationBar?.notification_bar_text}
          </Typography>
        </a>

        <Close className="pointer ms-3 ms-lg-5" onClick={() => setShowBar(false)} />
      </Box>
    </Box>
  );
}

export default NotificationBar;
