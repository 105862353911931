import { primary } from '../../common/colors';

export const getFooterIconStyles = color => ({
  cursor: 'pointer',
  '&:hover': {
    color,
  },
});

export const footerCopyrightStyle = {
  backgroundColor: primary,
  color: '#fff',
  width: '100%',
  textAlign: 'center',
  padding: '5px',
  marginTop: '5px',
};

export const footerStyle = {
  padding: '20px 0',
  bottom: 0,
  backgroundColor: '#FCFCFC',
};
