import React, { useCallback, useState } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { categoryBoxTitleStyles } from 'styles/mui/common/navbar-styles';

function CategoryBox({ label, categoryIcon, subCategories }) {
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleOpenMenu = useCallback(e => {
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleClickSubCategory = useCallback(name => {
    navigate(`/articles/${name}`);
    handleCloseMenu();
  }, []);

  return (
    <Box className="d-flex align-items-center justify-content-center pointer">
      <Box
        className="d-flex flex-column align-items-center justify-content-center gap-2"
        width="100px"
        onClick={handleOpenMenu}
      >
        <Box width={1} maxWidth={30}>
          <img className="img-fluid" src={categoryIcon} alt="Category" />
        </Box>

        <Typography variant="body2" textAlign="center" sx={categoryBoxTitleStyles}>
          {label}
        </Typography>
      </Box>

      <Menu
        key={!!menuAnchorEl}
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={handleCloseMenu}
        sx={{ '& .MuiPaper-root': { marginTop: '20px', minWidth: '130px' } }}
      >
        {subCategories?.map(({ name, id }) => (
          <MenuItem key={id} onClick={() => handleClickSubCategory(name)}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

CategoryBox.propTypes = {
  label: propTypes.string.isRequired,
  categoryIcon: propTypes.string.isRequired,
  subCategories: propTypes.array,
};

CategoryBox.defaultProps = {
  subCategories: [],
};

export default CategoryBox;
