import React, { useCallback } from 'react';
import { Menu, MenuItem } from '@mui/material';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAuth from 'custom-hooks/useAuth';
import { useSelector } from 'react-redux';

function ProfileMenu({ anchorEl, handleClose, canUserWriteArticle }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userProfileId = useSelector(state => state.auth?.user?.profile?.id);
  const { handleLogout } = useAuth();

  const handleNavigate = useCallback(path => {
    navigate(path);
    handleClose();
  }, []);

  return (
    <Menu
      key={!!anchorEl}
      sx={{ '& .MuiPaper-root': { marginTop: '20px', minWidth: '130px' } }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleNavigate(`/user/${userProfileId}`)}>Profile</MenuItem>

      {canUserWriteArticle && (
        <MenuItem onClick={() => handleNavigate('/user/write-article')}>{t('Write')}</MenuItem>
      )}

      <MenuItem
        onClick={() => {
          handleLogout();
          handleClose();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
}

ProfileMenu.propTypes = {
  anchorEl: propTypes.object,
  handleClose: propTypes.func.isRequired,
  canUserWriteArticle: propTypes.bool.isRequired,
};

ProfileMenu.defaultProps = {
  anchorEl: null,
};

export default ProfileMenu;
