import axios from 'axios';
import DOMPurify from 'dompurify';
import moment from 'moment';

import { API_URL } from 'utilities/constants';

export const getTimeAgo = time => {
  const now = moment();
  const then = moment(time);
  const diff = now.diff(then);
  const humanizedTime = moment.duration(diff).humanize(true);
  return `${humanizedTime} ago`;
};
export const getAboutTimeAgo = time => {
  const now = moment();
  const then = moment(time);
  const diffInHours = now.diff(then, 'hours');
  // Customize the display based on the time difference
  let displayText = '';
  if (diffInHours < 1) {
    displayText = 'a few minutes ago';
  } else if (diffInHours < 24) {
    displayText = `about ${diffInHours} hours ago`;
  } else {
    displayText = then.format('MMMM Do YYYY, h:mm:ss a');
  }
  return displayText;
};
export const getMonthDay = date => moment(date).format('MMM D');
export const getFullMonthDayYear = date => moment(date).format('MMMM D, YYYY');
export const getFullDate = date => moment(date).format('DD/MM/YYYY');
export const getCurrentYear = () => new Date().getFullYear();
export const getDayAndFullYear = date => moment(date).format('ddd DD/MM/YYYY');
export const getTime = date => moment.utc(date).format('h:mm A');
// Replace spaces with '+' in a string
export const convertSpacesToPlus = inputString => inputString.replace(/ /g, '+');
export const convertPlusToSpace = inputString => inputString.replace(/\+/g, ' ');
export const calculateReadTime = (text, wordsPerMinute = 150) => {
  const totalWords = text?.split(/\s+/).length;
  const totalMinutes = Math.floor(totalWords / wordsPerMinute);
  return totalMinutes > 0 ? totalMinutes : 1;
};
// Function to upload editor images to server
export const uploadImage = async (endpoint, file) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();

  const apiEndPoint = API_URL + endpoint;

  formData.append('image', file);
  const response = await axios.post(apiEndPoint, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
// Function to limit the number of words displayed
export const limitContent = (text, limit) => {
  const words = text.split(' ');
  if (words.length > limit) {
    return `${words.slice(0, limit).join(' ')}...`;
  }
  return text;
};
// Functions to convert complex objects to string
export const ConvertToString = data => {
  const jsonString = JSON.stringify(data, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      return value;
    }
    return value;
  });
  return jsonString;
};
// Functions to convert complex objects to string
export const ConvertStringToJson = data => data && JSON.parse(data);
// Functions to render editor.js data
export const sanitizeHTML = html => DOMPurify.sanitize(html);
// Get Whatsapp number from link
export const getWhatsaapNumber = whatsappLink => {
  const match = whatsappLink.match(/https:\/\/wa.me\/(\d+)/);
  return match[1];
};

export const sortDataByDate = (data = []) => {
  const sortedData = [...data]?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });
  return sortedData;
};

export const SortDataByPrice = (data = []) => {
  const sortedData = [...data]?.sort((a, b) => {
    const priceA = a.price;
    const priceB = b.price;
    return priceA - priceB;
  });
  return sortedData;
};

export const sanitizeHtml = htmlContent => ({ __html: DOMPurify.sanitize(htmlContent) });

export const getContentFromHtml = content => {
  const matchRegex = /<(h[1-6]|p)[^>]*>(.*?)<\/(h[1-6]|p)>/gi;
  const matches = content?.match(matchRegex) || [];
  const extractedContent = matches?.map(match => {
    const innerText = match.replace(/<\/?[^>]+(>|$)/g, '');
    return innerText;
  });
  const shortenedContent = extractedContent.join(' ').slice(0, 150);
  return `${shortenedContent} ...`;
};

export const getUserLevelIndex = userLevel => {
  const steps = ['audience', 'challenger', 'best_author', 'pro_author'];
  return steps.indexOf(userLevel);
};

export const calculateDiscount = (percentage, totalAmount) => {
  if (percentage < 0 || !percentage) {
    return {
      discountAmount: 0,
      discountedTotalAmount: totalAmount,
    };
  }
  const discount = parseFloat((percentage / 100) * totalAmount);
  const discountedTotal = parseFloat(totalAmount - discount);
  return {
    discountAmount: Math.round(discount * 100) / 100,
    discountedTotalAmount: Math.round(discountedTotal * 100) / 100,
  };
};

export const getTransformedUsersNameAndIDOptions = (data = []) => {
  const interests = data?.map(item => ({
    value: item?.id,
    label: item?.interest_name,
  }));

  return interests || [];
};

export const formatString = (inputString = '') => {
  // Split the input string by underscores
  const words = inputString.split('_');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the capitalized words with a space
  const formattedString = capitalizedWords.join(' ');

  return formattedString;
};

export const formatCurrency = (number = 0) => {
  const formattedNumber = new Intl.NumberFormat('en-US').format(number);
  return formattedNumber;
};

export const convertUrlToFile = async url => {
  try {
    if (url) {
      const response = await axios.get(url, { responseType: 'blob' });
      const filename = url?.split('/').pop();
      const file = new File([response.data], filename);

      return file;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('ERROR', err);
  }

  return null;
};

export const capitalizedWords = word => word.charAt(0).toUpperCase() + word.slice(1);

export function convertToWebP(imageFile) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let { width } = img;
      let { height } = img;

      // Resize if necessary
      if (width > 720 || height > 1080) {
        const ratio = Math.min(720 / width, 1080 / height);
        width *= ratio;
        height *= ratio;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(blob => {
        const transformedImage = new File([blob], imageFile.name.replace(/\.\w+$/, '.webp'), { type: 'image/webp' });
        resolve(transformedImage);
      }, 'image/webp');
    };

    img.onerror = reject;
  });
}

export function replaceSpacesWithDashes(str) {
  return str.replace(/\s+/g, '-');
}
