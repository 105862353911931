import React from 'react';
import { AppBar, Box } from '@mui/material';

import { categoriesNavbarStyle } from 'styles/mui/common/navbar-styles';
import { useGetCategoriesQuery } from 'services/public/category';
import CategoryBox from './CategoryBox';

function CategoriesNavbar() {
  const { data, isLoading } = useGetCategoriesQuery();

  if (isLoading) return null;

  return (
    <AppBar position="relative" sx={categoriesNavbarStyle}>
      <Box className="d-flex flex-row align-items-center justify-content-between gap-2">
        {data?.length > 0 &&
          data?.map(category => (
            <CategoryBox
              key={category?.id}
              label={category?.name}
              subCategories={category?.sub_categories}
              categoryIcon={category?.icon}
            />
          ))}
      </Box>
    </AppBar>
  );
}

export default CategoriesNavbar;
