import { primary, dark, secondary, muted, success, gray, white, grayLight } from 'styles/common/colors';

const { createTheme } = require('@mui/material');

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    allVariants: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
    },
    h1: {
      fontSize: '42px',
      fontWeight: '500',
      textTransform: 'capitalize',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '38px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '32px',
      },

      '@media (max-width: 575px)': {
        fontSize: '30px',
      },
    },

    h2: {
      fontSize: '42px',
      fontWeight: '500',
      textTransform: 'capitalize',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '38px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '32px',
      },

      '@media (max-width: 575px)': {
        fontSize: '30px',
      },
    },

    h3: {
      fontSize: '38px',
      fontWeight: '500',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '32px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '28px',
      },

      '@media (max-width: 575px)': {
        fontSize: '24px',
      },
    },

    h4: {
      fontSize: '34px',
      fontWeight: '500',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '28px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '24px',
      },

      '@media (max-width: 575px)': {
        fontSize: '20px',
      },
    },

    h5: {
      fontSize: '24px',
      fontWeight: '400',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '23px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '22px',
      },

      '@media (max-width: 575px)': {
        fontSize: '20px',
      },
    },
    h6: {
      fontSize: '20px',
      fontWeight: '400',

      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '18px',
      },

      '@media (max-width: 768px) and (min-width: 575px)': {
        fontSize: '16px',
      },

      '@media (max-width: 575px)': {
        fontSize: '16px',
      },
    },
    label: {
      display: 'block',
      fontSize: '0.9rem',
      fontWeight: 400,
      marginBottom: '6px !important',
    },
    pageTitle: {
      fontWeight: 500,
      textTransform: 'capitalize',
      fontSize: '20px',
    },
    subHead: {
      marginBottom: '20px',
      fontSize: '14px',
      opacity: 0.7,
    },
  },
  breakpoints: {},
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            textTransform: 'none',
            borderRadius: '5px',
            transition: 'all 0.4s',
            background: primary,
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            textTransform: 'none',
            borderRadius: '5px',
            transition: 'all 0.4s',
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            textTransform: 'none',
            borderRadius: '5px',
            transition: 'all 0.4s',
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            textTransform: 'none',
            borderRadius: '5px',
            transition: 'all 0.4s',
            background: secondary,
            color: dark,
            border: '1px solid transparent',
            '&:hover': {
              background: dark,
              color: secondary,
            },
          },
        },
      ],
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: 'white',
          borderRadius: '5px',
          padding: '10px 20px',
          boxShadow: '2px 2px 10px #d7d7d7',
          border: 'none',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    dark: {
      main: dark,
    },
    muted: {
      main: muted,
    },
    success: {
      main: success,
    },
    gray: {
      main: gray,
      light: grayLight,
    },
    white: {
      main: white,
    },
  },
});

export default theme;
