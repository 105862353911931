import React, { useCallback } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';

import { navOtherLinks } from '../utilities/data';

function OtherLinksMenu({ anchorEl, handleClose }) {
  const navigate = useNavigate();

  const handleNavigate = useCallback(path => {
    navigate(path);
    handleClose();
  }, []);

  return (
    <Menu
      key={!!anchorEl}
      sx={{ '& .MuiPaper-root': { marginTop: '20px', minWidth: '130px' } }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      {navOtherLinks.map(link => (
        <MenuItem key={link.id} onClick={() => handleNavigate(link.url)}>
          {link.name}
        </MenuItem>
      ))}
    </Menu>
  );
}

OtherLinksMenu.propTypes = {
  anchorEl: propTypes.object,
  handleClose: propTypes.func.isRequired,
};

OtherLinksMenu.defaultProps = {
  anchorEl: null,
};

export default OtherLinksMenu;
