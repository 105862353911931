import React, { memo, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSelectedLanguage } from 'store/slices/publishArticleSlice';

const googleTranslateElementInit = () => {
  // eslint-disable-next-line no-new
  new window.google.translate.TranslateElement(
    {
      pageLanguage: 'en',
      includedLanguages: 'ko,ja,ar,pt,en',
      autoDisplay: false,
    },
    'google-translator-element'
  );
};

function GoogleTranslator() {
  const translatorRef = useRef(null);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useLayoutEffect(() => {
    if (translatorRef.current) {
      const timeoutId = setTimeout(() => {
        const selectField = translatorRef.current.querySelector('.goog-te-combo');

        selectField?.addEventListener('change', e => {
          dispatch(setSelectedLanguage(e.target.value));
          i18n.changeLanguage(e.target.value);
        });
      }, [2500]);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return () => {};
  }, [translatorRef.current]);

  return (
    <div className="d-none d-sm-flex align-items-center">
      <div ref={translatorRef} id="google-translator-element" />
    </div>
  );
}

export default memo(GoogleTranslator);
