export const HideFooterRoutes = [
  { value: 'write-article', id: 1 },
  { value: '/auth/otp-verification', id: 2 },
  { value: '/auth/forgot-password', id: 3 },
  { value: '/auth/signup', id: 4 },
  { value: '/auth/login', id: 5 },
];

export const articleLanguages = [
  'All',
  'English',
  'Arabic',
  'Korean',
  'Japanese',
  'Portuguese'
];
