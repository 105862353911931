import { publicApi } from '.';

export const bannersApi = publicApi.injectEndpoints({
  endpoints: build => ({
    getAllBanners: build.query({
      query: () => ({
        url: '/api/admin-panel/banner-list/',
        method: 'GEt',
      }),
    }),
    getRankSliderBanners: build.query({
      query: () => ({
        url: '/api/admin-panel/slide-banner-list/',
        method: 'GEt',
      }),
    }),
  }),
});

export const { useGetAllBannersQuery, useGetRankSliderBannersQuery } = bannersApi;
