import { primary, white } from 'styles/common/colors';

const miniVariantWidth = '80px';
const drawerWidth = '240px';

export const announcementBarStyle = {
  backgroundColor: '#5D6793',
  px: '5px',
  textAlign: 'center',
  color: '#fff',
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 1000,
  overflowX: 'scroll',
  whiteSpace: 'nowrap',

  '::-webkit-scrollbar': { height: '2px' },
  '::-webkit-scrollbar-thumb': { background: '#b3b3b3' },
};

export const sideBarSearchStyles = {
  backgroundColor: 'rgb(1,9,87)',
  borderRadius: '5px',
  margin: '10px',
};

export const getBoxWrapperStyles = isSidebarCollapsed => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  marginLeft: isSidebarCollapsed ? miniVariantWidth : drawerWidth,
  transition: '0.2s ease-in-out',

  '@media screen and (max-width: 768px)': {
    marginLeft: 0,
  },
});

export const getSidebarWrapperStyles = isSidebarCollapsed => ({
  position: 'fixed',
  left: '0',
  top: '0',
  bottom: '0',
  background: primary,
  transition: '0.2s ease-in-out',
  whiteSpace: 'nowrap',
  height: '100vh',
  color: white,
  width: isSidebarCollapsed ? miniVariantWidth : drawerWidth,
  overflowY: 'scroll !important',
  '::-webkit-scrollbar': { width: '3px' },
  '::-webkit-scrollbar-thumb': { background: '#b3b3b3' },

  '@media screen and (max-width: 768px)': {
    width: 0,
  },
});

export const getListItemBtnStyles = (isActive = false) => ({
  px: 1.5,
  width: '100%',
  color: 'white',
  background: isActive ? '#050C51' : 'initial',
  transition: '0.2s ease-in-out',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'between',
  '&:hover': {
    background: '#050C51',
  },

  '& .MuiTypography-root': {
    transition: '0.2s ease-in-out',
    width: '100%',
    whiteSpace: 'break-spaces',
    ml: 1,
  },

  '& svg': {
    width: '24px',
    height: '24px',
    color: 'white',
    fill: 'white',
  },

  '& .subcat-open-icon': {
    zIndex: 10,
  },

  '@media screen and (max-width: 768px)': {
    minHeight: 50,
    '& .MuiTypography-root': {
      display: 'none',
      fontSize: '13px',
      transition: '0.2s ease-in-out',
    },
  },
});

export const collapseStyle = isCollapsed => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isCollapsed && 'center',
  width: '100% !important',
  '& .sidebar-hide-item': {
    display: isCollapsed ? 'none !important' : 'block',
  },
});

export const subMenuStyle = () => ({
  color: '#fff',
  backgroundColor: '#171E60',
  whiteSpace: 'nowrap',
  fontSize: '12px',
  transition: 'all 0.4s ease',
  // display: isHovering ? 'block' : 'none',
  display: 'block',
  // position: 'absolute',
  // top: '0',
  // right: '-200px',
  // minWidth: '200px',
  // zIndex: 50,
});

export const subMenuItemStyle = {
  '&:hover': {
    background: '#050C51',
  },
  fontSize: '14px',
};

export const overflowStyle = {
  flex: 1,
};

export const sideBarSettingBox = isCollapsed => ({
  // position: 'absolute',
  // bottom: 0,
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isCollapsed && 'center !important',
  alignItems: isCollapsed && 'center',
  width: '100% !important',
});
