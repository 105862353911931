const colors = {
  primary: '#171E60',
  secondary: '#DBDBDB',
  dark: '#050C51',
  muted: '#999999',
  border: '#ced4da',
  disabled: '#f2f4f7',
  primaryHover: '#00598d',
  success: '#1a8917',
  gray: '#D3D3D3',
  grayLight: '#f9f9f9',
  white: '#FFFFFF'
};

export const { primary, primaryHover, muted, secondary, dark, border, disabled, success, gray, white, grayLight } = colors;

export default colors;
