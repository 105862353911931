/* eslint-disable no-new */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';

// COMPONENTS & UTILITIES
import theme from 'styles/mui/GeneralCustomTheme';
import { useAuthorizedQuery } from 'services/private/auth';
import { onAuthorized, onLoggedOut } from 'store/slices/authSlice';
import AppRoutes from './routes';
import '../i18n';

function App() {
  const dispatch = useDispatch();
  const { data, isSuccess, isLoading, isError } = useAuthorizedQuery();

  useEffect(() => {
    if (isSuccess) {
      dispatch(onAuthorized(data));
    } else if (isError) {
      dispatch(onLoggedOut());
    }
  }, [data, isLoading, isSuccess]);

  return (
    <HelmetProvider>
      <GoogleOAuthProvider clientId="361966573363-sba4eaojos3c2vn3s8qjsg16nurehjav.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} autoHideDuration={2000}>
            {!isLoading && <AppRoutes />}
          </SnackbarProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </HelmetProvider>
  );
}

export default App;
