export const API_URL = process.env.REACT_APP_API_URL;

export const TINY_EDITOR_API_KEY = process.env.REACT_APP_TINY_EDITOR_API_KEY;

export const PAYPAL_KEY = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const GOOGLE_AD_CLIENT = process.env.REACT_APP_GOOGLE_AD_CLIENT;

export const GOOGLE_AD_SLOT = process.env.REACT_APP_GOOGLE_AD_SLOT;

export const articlePerColumn = 6;

// ROLES AND PERMISSIONS
export const USER = 'user';
export const AUDIENCE = 'audience';
