import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openDialog: false,
  selectedLanguage: 'en',
  editorKey: 1,
  articleData: {
    title: '',
    article: '',
  },
  isSaved: false,
  id: null,
  slug: null,
  isUpdated: false,
  isLoading: false,
  thumbnail: null,
  selectedArticle: null
};

const publishArticleSlice = createSlice({
  name: 'publishArticle',
  initialState,
  reducers: {
    setOpenDialog: (state, action) => ({
      ...state,
      openDialog: action.payload,
    }),
    setArticleData: (state, action) => {
      const prevState = state.articleData;
      const isChanged = Object.keys(action.payload).some(key => prevState[key] !== action.payload[key]);

      return {
        ...state,
        articleData: { ...action.payload },
        isUpdated: isChanged,
      };
    },
    setSelectedLanguage: (state, action) => ({
      ...state,
      selectedLanguage: action.payload,
    }),
    setEditorKey: (state, action) => ({
      ...state,
      editorKey: action.payload,
    }),
    updateArticleInfo: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    clearArticleData: state => ({
      ...state,
      articleData: {
        title: '',
        article: '',
      },
      id: null,
      isSaved: false,
      isUpdated: false,
      isLoading: false,
    }),
    setLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
});

export const {
  setOpenDialog,
  setSelectedLanguage,
  setArticleData,
  setEditorKey,
  updateArticleInfo,
  clearArticleData,
  setLoading,
} = publishArticleSlice.actions;

export default publishArticleSlice.reducer;
