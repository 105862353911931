/* eslint-disable no-console */
import React, { memo, useMemo } from 'react';
import { Stack, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import useArticleActionButtons from 'custom-hooks/useArticleActionButtons';

function ArticleActionButtons({ isSmallDevice }) {
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const { articleData, id: articleId, isLoading } = useSelector(reduxState => reduxState.publishArticle);
  const articleIdStatus = useMemo(() => state, [state]);
  const { handleClearDraft, handleSaveDraft, handlePublishDialogOpen } = useArticleActionButtons();

  const isButtonsDisabled = useMemo(
    () => isLoading || !articleData?.title || !articleData?.article,
    [articleData, isLoading]
  );

  return (
    <Stack className="translate" direction="row" justifyContent="center" alignItems="center" spacing={2}>
      {!articleIdStatus?.requestArticleId && (
        <>
          {(articleIdStatus?.draftArticleId || articleId) && (
            <Button
              variant="contained"
              color={isSmallDevice ? 'secondary' : 'primary'}
              disabled={isButtonsDisabled}
              onClick={handleClearDraft}
            >
              Clear Draft
            </Button>
          )}

          <Button
            variant="contained"
            color={isSmallDevice ? 'secondary' : 'primary'}
            disabled={isButtonsDisabled}
            onClick={() => {
              handleSaveDraft();
              enqueueSnackbar('Draft saved successfully!', { variant: 'success' });
            }}
          >
            {isLoading ? 'Saving...' : 'Save Draft'}
          </Button>
        </>
      )}

      <Button
        variant="contained"
        color={isSmallDevice ? 'secondary' : 'primary'}
        onClick={handlePublishDialogOpen}
        disabled={isButtonsDisabled}
      >
        Publish
      </Button>
    </Stack>
  );
}

ArticleActionButtons.propTypes = {
  isSmallDevice: propTypes.bool.isRequired,
};

export default memo(ArticleActionButtons);
