import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import { useGetCategoriesQuery } from 'services/public/category';
import { getBoxWrapperStyles, getSidebarWrapperStyles } from 'styles/mui/common/layout-styles';
import { HideFooterRoutes } from 'utilities/data';
import NavBar from './components/NavBar';
import Sidebar from './components/SideBar';
import Footer from './components/Footer';
import SectionLoader from '../loader/GlobalLoader';
import CategoriesNavbar from './components/CategoriesNavbar';
import NotificationBar from './components/NotificationBar';

function LayoutWrapper() {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { pathname } = useLocation();
  const isSmallDevice = useMediaQuery('@media screen and (max-width: 768px)');
  const { data, isLoading } = useGetCategoriesQuery();

  const isArticleOpen = HideFooterRoutes.some(route => pathname.includes(route.value));
  const isHomePageOpen = HideFooterRoutes.some(() => pathname === '/');

  const toggleSidebarCollapse = () => {
    if (!isSmallDevice) {
      setSidebarCollapsed(!isSidebarCollapsed);
    }
  };

  useEffect(() => {
    if (!isSmallDevice) {
      setSidebarCollapsed(false);
    } else {
      setSidebarCollapsed(true);
    }
  }, [isSmallDevice]);

  return (
    <>
      <Box sx={getSidebarWrapperStyles(isSidebarCollapsed)}>
        {isLoading ? (
          <SectionLoader />
        ) : (
          <Sidebar categories={data} collapse={isSidebarCollapsed} toggleSidebar={toggleSidebarCollapse} />
        )}
      </Box>

      <Box sx={getBoxWrapperStyles(isSidebarCollapsed)}>
        <NavBar />
        <CategoriesNavbar />
        <NotificationBar />
        <Box sx={{ minHeight: '80vh' }}>
          <Outlet />
        </Box>
        {!isArticleOpen && !isHomePageOpen && <Footer />}
      </Box>
    </>
  );
}

export default LayoutWrapper;
