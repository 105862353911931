import React from 'react';
import { Box, Avatar, Typography, Divider, Stack } from '@mui/material';
import {
  List,
  PersonOutline,
  Settings,
  Logout,
  WebOutlined,
  Login,
  ListAlt,
  QrCode,
  Diversity2,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import SearchInput from 'shared/components/SearchInput';
import {
  collapseStyle,
  sideBarSearchStyles,
  sideBarSettingBox,
  overflowStyle,
} from 'styles/mui/common/layout-styles';
import useAuth from 'custom-hooks/useAuth';
import logo from 'assets/logo.png';
import { convertSpacesToPlus, formatString } from 'utilities/helpers';
import SidebarLink from './SideBarLink';

function SideBar({ toggleSidebar, collapse, categories }) {
  const { user, UserProfile, handleLogout, userRole, isAuthenticated } = useAuth();

  return (
    <Box sx={collapseStyle(collapse)}>
      <Box
        className={
          collapse
            ? 'customFlexBetween flex-column gap-2 px-3 mb-5 pt-2'
            : 'py-4 gap-2 px-3 customFlexBetween'
        }
      >
        <Box component={Link} to="/" sx={{ maxWidth: '80px' }}>
          <img className="img-fluid" src={logo} alt="Logo" />
        </Box>
        <List color="white" className="pointer" onClick={() => toggleSidebar()} />
      </Box>
      {!collapse && (
        <Box sx={sideBarSearchStyles} component="form" noValidate autoComplete="off">
          <SearchInput />
        </Box>
      )}
      {isAuthenticated && (
        <Box className={collapse ? 'customFlexCenter order-1 py-2' : 'd-flex gap-2 m-3'}>
          <Avatar alt="user image" src={user?.profile?.image} sx={{ width: 48, height: 48 }} />
          <Box className={collapse ? 'd-none' : 'capitalize'}>
            <Typography variant="body2" className="wrapText capitalize">
              {UserProfile?.first_name} {UserProfile?.last_name}
            </Typography>
            <Typography display="block" variant="caption" className="capitalize">
              {userRole === 'partner' ? 'Partner' : formatString(user?.profile?.author_rank)}
            </Typography>
          </Box>
        </Box>
      )}
      <Stack sx={overflowStyle} className="side-bar-item mb-2" gap={1}>
        {isAuthenticated && (
          <SidebarLink title="My Profile" path={`/user/${user?.profile?.id}`} icon={<PersonOutline />} />
        )}
        {userRole === 'partner' && <SidebarLink title="My Brands" path="/partner" icon={<WebOutlined />} />}
        {categories?.map(category => (
          <SidebarLink
            key={category?.id}
            id={category?.id}
            title={category?.name}
            path={`/articles/${convertSpacesToPlus(category?.name)}`}
            icon={category?.icon}
            isMenu
            subCategories={category?.sub_categories}
            collapse
          />
        ))}
        <SidebarLink title="Secret Box" path="coupons" icon={<QrCode />} />
        <SidebarLink title="Discovery" path="discovery" icon={<Diversity2 />} />
        <SidebarLink title="Blogs" path="blogs" icon={<ListAlt />} />
      </Stack>
      <Box className="side-bar-item pb-2" sx={sideBarSettingBox(collapse)}>
        <Divider sx={{ bgcolor: '#fff', height: 4 }} />
        {isAuthenticated ? (
          <>
            <SidebarLink title="Settings" path="me/settings/profile" icon={<Settings />} />
            <SidebarLink title="Logout" icon={<Logout />} handleClick={handleLogout} />
          </>
        ) : (
          <>
            <SidebarLink title="Login" icon={<Avatar />} path="/auth/login" />
            <SidebarLink title="Signup" icon={<Login />} path="/auth/signup" />
          </>
        )}
      </Box>
    </Box>
  );
}

SideBar.propTypes = {
  toggleSidebar: propTypes.func.isRequired,
  collapse: propTypes.bool.isRequired,
  categories: propTypes.array,
};

SideBar.defaultProps = {
  categories: [],
};

export default SideBar;
