import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { convertSpacesToPlus } from 'utilities/helpers';
import SearchTextField from 'containers/common/Components/styled/SearchTextField';

function SearchInput({ color }) {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (searchQuery.trim() !== '') {
        event.preventDefault();
        setSearchQuery('');
        navigate(`articles/search/${convertSpacesToPlus(searchQuery)}`);
      }
    }
  };

  const onClickSearch = event => {
    if (searchQuery.trim() !== '') {
      event.preventDefault();
      setSearchQuery('');
      navigate(`articles/search/${convertSpacesToPlus(searchQuery)}`);
    }
  };

  return (
    <SearchTextField
      type="text"
      placeholder="Search"
      autoComplete="off"
      value={searchQuery}
      onChange={e => setSearchQuery(e.target.value)}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClickSearch} aria-label="search">
              <Search color={color} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

SearchInput.propTypes = {
  color: propTypes.string,
};

SearchInput.defaultProps = {
  color: 'white',
};

export default SearchInput;
